import PropTypes from 'prop-types'
import React from 'react'
import tw, { css } from 'twin.macro'
import { StyleType } from '../../utils/prop-types'

const positionStyle = {
  left: tw`absolute top-0 bottom-0 left-0`,
  right: tw`absolute top-0 bottom-0 right-0`,
  'bottom-left': tw`absolute bottom-0 left-0`,
  'bottom-right': tw`absolute bottom-0 right-0`,
}

const visibilityStyle = {
  desktop: tw`invisible hidden overflow-visible lg:(block visible)`,
  mobile: tw`visible overflow-visible lg:(hidden invisible)`,
}

const Overlay = ({ style, visibility, position, width, height, children }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${width} ${height}`}
      css={[
        css`
          ${tw`z-10 pointer-events-none`}
          width: ${width}px;
        `,
        positionStyle[position],
        visibilityStyle[visibility],
        style,
      ]}
    >
      {children}
    </svg>
  )
}

Overlay.propTypes = {
  style: StyleType,
  visibility: PropTypes.oneOf(['desktop', 'mobile']),
  position: PropTypes.oneOf(['bottom-left', 'left', 'bottom-right', 'right']),
  width: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.node,
}

export default Overlay
