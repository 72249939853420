import React from 'react'
import Overlay from './overlay'
import Rectangle from './rectangle'

export const overlays = {
  hero: (
    <>
      <Overlay visibility="desktop" position="bottom-left" width={28} height={184}>
        <Rectangle x={0} y={0} width={28} height={28} fill="white" />
      </Overlay>
      <Overlay visibility="desktop" position="right" width={730} height={140}>
        <Rectangle x={0} y={84} width={112} height={56} fill="white" />
      </Overlay>
      <Overlay visibility="desktop" position="bottom-right" width={168} height={246}>
        <Rectangle x={0} y={0} width={168} height={28} fill="yellow" />
      </Overlay>
      <Overlay visibility="mobile" position="bottom-right" width={56} height={186}>
        <Rectangle x={0} y={0} width={56} height={18} fill="yellow" />
      </Overlay>
    </>
  ),
}
