import PropTypes from 'prop-types'
import React from 'react'
import { css, theme } from 'twin.macro'
import { global } from '../../styles/global'

const palette = {
  white: theme`colors.white`,
  navy: theme`colors.primary.500`,
  cyan: theme`colors.secondary.500`,
  yellow: theme`colors.accent.500`,
}

const Rectangle = ({ width, height, fill, x, y, style, ...props }) => {
  return (
    <rect
      css={[
        global`animate.block`,
        css`
          fill: ${palette[fill]};
        `,
        style,
      ]}
      width={width}
      height={height}
      x={x}
      y={y}
      {...props}
    />
  )
}

Rectangle.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.oneOf(['white', 'yellow', 'navy', 'cyan']),
  x: PropTypes.number,
  y: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default Rectangle
